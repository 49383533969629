import React from 'react'
import '../../styles/start.css'
import trainingImg from '../../assets/img/drive.jpeg'

const Start = () => {
  return (
    <section id='classes'>
      <div className='container'>
        <div className='start_wrapper'>
          <div className='start_img'>
            <img
              src={trainingImg}
              alt=''
              data-aos='fade-left'
              data-aos-duration='1500'
            />
          </div>

          <div
            className='start_content'
            data-aos='fade-right'
            data-aos-duration='1500'
          >
            <h2 className='section_title'>
              Ready to play <span className='highlights'>Cover drive?</span>
            </h2>
            <p>
            The cover drive is considered one of the most graceful shots playable in the sport. 
            Batsmen able to master the cover drive are usually given especially high praise 
            because of the shot's difficulty in execution and its requirement for exquisite timing. 
            When played to perfection, the cover drive comprises a batsman's seemingly effortless 
            wielding of the blade in a downward trajectory through the off side, 
            with the cricket ball gliding through infielders in the covers at pace toward the 
            boundary at deep cover.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Start
