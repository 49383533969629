import React from 'react'
import heroImg from '../../assets/img/batsman_bk.png'
import dumbleIcon from '../../assets/img/ball_fire.png'
import '../../styles/Hero.css'

const Hero = () => {
  return (
    <section id='home'>
      <div className='container'>
        <div className='hero_wrapper'>
          {/* ======= hero content ====== */}
          <div className='hero_content'>
            <h2
              className='section_title'
              data-aos='fade-up'
              data-aos-duration='1500'
            >
              Cricket is not only Young's passion it’s our <span className='highlights'>Life & Attitude</span>{' '}

            </h2>
            <p data-aos='fade-up' data-aos-delay='100' data-aos-duration='1800'>
            Play Life like a Cricket match. Don’t try to hit hard in every situation, 
            Just keep rotating, moving, and then look for that one Delivery 
            and hit it as hard as you can.
            </p>

            <div
              className='hero_btns'
              data-aos='fade-up'
              data-aos-delay='200'
              data-aos-duration='2000'
            >
            </div>
          </div>

          {/* ======= hero img ====== */}
          <div className='hero_img'>
            <div className='hero_img-wrapper'>
              <div className='box-01'>
                <div className='box-2'>
                  <div className='box-3'>
                    <div className='box_img'>
                      <img src={heroImg} alt='' />
                    </div>
                  </div>
                </div>
              </div>

        
              <div
                className='dumble_icon'
                data-aos='fade-down'
                data-aos-duration='1500'
              >
                <img src={dumbleIcon} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
