import React from 'react'
import '../../styles/testimonials.css'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cards'

// import required modules
import { EffectCards } from 'swiper'
import avatar01 from '../../assets/img/ground.jpeg'
import avatar02 from '../../assets/img/play.jpeg'
import avatar03 from '../../assets/img/stumped.jpeg'

export default function Testimonials() {
  return (
    <>
      <section>
        <div
          className='container-img sliders'
          data-aos='zoom-in'
          data-aos-duration='1500'
        >
          <h2 className='section_title'>Gallery</h2>
          <Swiper
            effect={'cards'}
            grabCursor={true}
            modules={[EffectCards]}
            className='mySwiper'
          >
            <SwiperSlide>
              <div className='slide_item'>
                <div className='slide_img-01'>
                  <img src={avatar01} alt='' />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='slide_item'>
                <div className='slide_img-02'>
                  <img src={avatar02} alt='' />
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className='slide_item'>
                <div className='slide_img-03'>
                  <img src={avatar03} alt='' />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  )
}
